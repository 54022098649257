
import CurrencyDropDown from "../CurrencyDropDown/index.vue";

export default {
  inheritAttrs: false,

  components: { CurrencyDropDown },

  props: {
    header: {
      type: String,
      default: undefined,
    },

    /*
    [
      {
        label:String,
        width:String,
        textAlignEnd:Boolean(optional)
      }
    ]
    */
    dataHeaders: {
      type: Array,
      required: true,
    },

    /*
    [
      {
        id: Number
        columns: [
          {
            editable: Boolean,
            type: String("text"/"number"/"date"),
            value: String/Number
            copyType: String("body"/"header")
          }
        ]
      }
    ]
    */
    data: {
      type: Array,
      required: true,
    },

    /*
    [
      {
        editable: Boolean,
        type: String("text"/"number"/"date"),
        value: String/Number
        copyType: String("body"/"header")
      }
    ]*/
    dataInputFormat: {
      type: Array,
      required: true,
    },

    showDuplicateOption: {
      type: Boolean,
      default: false,
    },

    /*
     * TOP, BOTTOM, BOTH
     */
    addNewPosition: {
      type: String,
      default: "BOTTOM",
    },

    loading: {
      type: Boolean,
      default: false,
    },

    validateInput: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dataInternal: JSON.parse(JSON.stringify(this.data)), // Deep copy so we don't mutate the original data
      validate: {
        number: this.numberValidator,
        date: this.dateValidator,
      },
      dataValid: true,
    };
  },

  watch: {
    data() {
      this.dataInternal = JSON.parse(JSON.stringify(this.data));
    },
  },

  methods: {
    save() {
      const newRecords = this.dataInternal.filter((keyValue) => keyValue.id === 0);
      const deletedRecords = this.data.filter((record) => !this.dataInternal.find((keyValue) => keyValue.id === record.id));
      const updatedRecords = this.dataInternal.filter(
        (keyValue) => keyValue.id != 0 && JSON.stringify(keyValue) !== JSON.stringify(this.data.find((record) => record.id === keyValue.id))
      );

      this.$emit("save", {
        newRecords,
        deletedRecords,
        updatedRecords,
      });
    },

    cancel() {
      this.$emit("close");
    },

    remove(index) {
      this.dataInternal.splice(index, 1);
    },

    addNew() {
      this.dataInternal.push({
        id: 0,
        columns: JSON.parse(JSON.stringify(this.dataInputFormat)),
      });
    },

    numberValidator(value) {
      return !isNaN(value);
    },

    dateValidator(value) {
      return !isNaN(Date.parse(value));
    },
  },
};
